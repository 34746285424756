.image-component {
  margin: 0;
  width: 100%;
  position: relative; }
  .image-component .link-image-text {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 98; }

.common-image-component {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 0% 0%;
     object-position: 0% 0%;
  position: relative; }

.common-image-filter {
  filter: brightness(40%); }

.image-heading-container {
  position: absolute;
  width: 84%;
  z-index: 99;
  bottom: 54px;
  left: 0;
  right: 0;
  margin: auto; }
  @media (max-width: 839.98px) {
    .image-heading-container {
      bottom: 48px;
      width: 88%; } }

.col-ITC-text {
  width: auto; }
  @media (max-width: 839.98px) {
    .col-ITC-text {
      width: 100%; } }

/* Disabling SKP-580 - SKP-717 UAT - Revert ticket SKP-580 - Bordures noires */
.ITC-heading-text {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase; }
  .ITC-heading-text p {
    font-size: inherit;
    line-height: inherit;
    font-size: 24px;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 10px; }
  .ITC-heading-text .ITC-cta-label {
    margin-top: 14px;
    text-align: center;
    display: flex;
    font-size: inherit;
    flex-wrap: nowrap;
    justify-content: center; }
    .ITC-heading-text .ITC-cta-label a {
      text-align: center;
      max-width: 100%;
      display: block;
      margin-left: 2px; }
      @media (max-width: 543.98px) {
        .ITC-heading-text .ITC-cta-label a {
          max-width: 100%;
          margin-left: 0; } }
  .ITC-heading-text .col-12.d-flex.center {
    padding-left: 0; }
  @media (max-width: 839.98px) {
    .ITC-heading-text .col-12.d-flex.d-flex.center {
      padding-left: 0; } }
  @media (max-width: 839.98px) {
    .ITC-heading-text .left.col-12.d-flex {
      padding-left: 0; } }
  .ITC-heading-text .left.col-12 .ITC-cta-label a {
    margin-left: 0;
    margin-right: 24px; }
    .ITC-heading-text .left.col-12 .ITC-cta-label a:last-child {
      margin: 0; }
    @media (max-width: 839.98px) {
      .ITC-heading-text .left.col-12 .ITC-cta-label a {
        margin-left: 0;
        margin-right: 18px; }
        .ITC-heading-text .left.col-12 .ITC-cta-label a:last-child {
          margin: 0; } }
  .ITC-heading-text .right.col-12 {
    justify-content: end;
    padding-left: 0; }
    @media (max-width: 839.98px) {
      .ITC-heading-text .right.col-12.d-flex {
        padding-left: 0; }
      .ITC-heading-text .right.col-12 .col-ITC-text {
        width: 100%;
        text-align: right; } }
    @media (max-width: 543.98px) {
      .ITC-heading-text .right.col-12 {
        padding: 0;
        text-align: right; } }
    .ITC-heading-text .right.col-12 .ITC-cta-label a {
      margin-left: 0;
      margin-right: 24px; }
      .ITC-heading-text .right.col-12 .ITC-cta-label a:last-child {
        margin: 0; }
      @media (max-width: 839.98px) {
        .ITC-heading-text .right.col-12 .ITC-cta-label a {
          margin-left: 0;
          margin-right: 18px; }
          .ITC-heading-text .right.col-12 .ITC-cta-label a:last-child {
            margin: 0; } }
  .ITC-heading-text .center {
    justify-content: center;
    text-align: center; }
    .ITC-heading-text .center .btn-cta-default {
      margin: 0 12px;
      min-width: 136px;
      line-height: 18px;
      letter-spacing: 0.1655px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (max-width: 839.98px) {
        .ITC-heading-text .center .btn-cta-default {
          margin: 0 9px;
          min-width: 91px;
          height: 27px;
          line-height: 12px; } }
  .ITC-heading-text .white {
    color: #fff; }
  @media (max-width: 839.98px) {
    .ITC-heading-text {
      font-size: 18px; }
      .ITC-heading-text p {
        font-size: 18px; }
      .ITC-heading-text .ITC-cta-label {
        font-size: 8px; } }
